import Row from './components/Row';
import io from "socket.io-client";
import React, { useState, useEffect, createContext } from "react"
import { useNavigate } from 'react-router';
import uuid from 'react-uuid'
import sound from './multimedia/sound.mp3'
import ToggleButton from './components/ToggleButton';
import Constants from './Constants';
import Context from './Contexto';
import Spinner from './Spinner';


function RoomCommon() {
  let cont = 0;
  
  const context = React.useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    // console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const roomId = urlParams.get('id');
    console.log(roomId);
    if(roomId === null){
      navigate('/');
    }


    const socket = io(Constants.SERVER);
    // socket.emit('joinTheRoom', roomId);
    socket.on("room", data => {
      // The sound only can be played when the users had intereact with the website
      // if (document.getElementById('toggleInputButton').checked){
      //   var audio = new Audio(sound);
      //   audio.play();
      // }
      console.log(data);
      // setResponse(data);
      if(data){
        context.setRoomSelected(data);
      }
      else{
        console.log('UUUPDS');
        navigate('/error');
        context.setRoomSelected({ turns: [] });
      }
      

    });

    socket.on('connect', () => {
      console.log('Socket connected');
      socket.emit('joinTheRoom', roomId);
      context.setLoading(false);

    });
    socket.on('disconnect', () => {
      console.log('Socket disconnect');
      context.setLoading(true);

    });




  }, []);
  if (context.roomSelected != null) {
    return (
      <div className='container-room-common'>
        <Spinner cargando = {context.loading ? true: false}/>
        {/* Algo */}
        {/* Top and right */}
        {/* <div onClick = {() =>{console.log('Audio activated')}}>Audio</div> */}
        {/* There are conflics beacouse the input class chares code with other input */}
        {/* <ToggleButton/> */}
        
        {context.roomSelected.turns.map((item, i) => {
          if (item.active) {
            cont += 1;
            if (cont % 2 === 0) {
              return <Row key={uuid()} indexTurn={i} evenOrOdd="even" />
            }
            else {
              return <Row key={uuid()} indexTurn={i} evenOrOdd="odd" />
            }
          }



        })}

      </div>
    )
  }
  else {
    return (
      <div><Spinner cargando={true} /></div>
    )
  }

}

export default RoomCommon;
