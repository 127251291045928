import React from 'react'
import { useState, useEffect } from 'react';
import Constants from './Constants';
import { useNavigate } from 'react-router';
import Context from './Contexto';
import Utilities from './Utilities';
import Spinner from './Spinner';


export default function LoggingManager() {
    const context = React.useContext(Context);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        context.setUserId("");
        context.setLoading(false);
    }, [])

    function changeStateUserId(userId) {
        context.setUserId(userId);
    }
    function checkUser(event) {
        setUser(event.target.value);
    }
    function checkPass(event) {
        setPassword(event.target.value);
    }
    function sendForm(event) {
        // console.log(Constants.SERVER);
        event.preventDefault();
        context.setLoading(true);
        // Utilities.setCookie("token",JSON.stringify({nombre:"jose",apellidos:"yague"}),90);
        // let cookie = JSON.parse(Utilities.getCookie("token"))
        // console.log(cookie.nombre);

        let data = { user: user, password: password };
        fetch(`${Constants.SERVER}/login`, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // context.setUserId("VENGA");
                    changeStateUserId();
                    console.log(result);
                    if (result.userId) {
                        Utilities.setCookie("store", JSON.stringify({ userId: result.userId }), 1);
                        // Can`t be changed the state directly inside a fecth closure             
                        changeStateUserId(result.userId + "");
                        console.log(Utilities.checkSubscriptionDays(result.subscriptionTimestamp));
                        if (!Utilities.checkSubscriptionStatus(result.subscriptionTimestamp)) {
                            console.log('Subscripcion caducada');
                            context.setSubscriptionFinished(true);
                            navigate('/settings/payments');
                            return
                        }
                        // else{
                        //     console.log('Subscripcion estable');
                        //     return

                        // }
                        context.setLoading(false);
                        navigate('/manager');
                    }
                    else {
                        context.setLoading(false);
                        alert("User or password incorrect, please try again");
                    }

                },
                (error) => {
                    changeStateUserId("");
                    context.setLoading(false);
                    alert("User or password incorrect, please try again");
                    console.log(error);
                }
            )
    }
    return (
        <div className="logging-manager-container">
            <Spinner cargando={context.loading ? true : false} />



            <form className="half-width">
                <h3>Log in</h3>

                <label htmlFor="fname">User</label>
                <input type="text" id="fname" name="firstname" placeholder="Your user" value={user} onChange={checkUser} />

                <label htmlFor="lname">Password</label>
                <input type="password" id="lname" name="lastname" placeholder="Your password" onChange={checkPass} />

                <a className="forgot-your-password" href="/password/recovery">Forgot your password?</a>
                <input type="submit" value="Go!" onClick={sendForm} />
            </form>
            <a onClick={() => navigate("/about")}><div className="liver-in-loggin-room">more about <b>liver</b></div></a>
        </div>
    )
}
