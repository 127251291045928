import React from 'react'
import Context from '../Contexto';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Constants from '../Constants';
import Spinner from '../Spinner';
import BotonVolver from '../components/BotonVolver';
import Utilities from '../Utilities';

export default function AccountInfo() {

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("")
    const [name, setName] = useState("");

    const [direction, setDirection] = useState("");
    const [result, setResult] = useState(null);
    const context = React.useContext(Context);
    const navigate = useNavigate();

    useEffect(() => {
        changeStateLoading(true);


        try {
            // BEGINING Check cookie and context
            let data = {};
            if (context.userId === "") {
                try {
                    let cookieUserId = Utilities.getUserIdFromTheCookie();
                    if (cookieUserId) {
                        data = { userId: cookieUserId };
                    }
                    else {
                        navigate('/manager/login');
                    }

                } catch (error) {
                    navigate('/manager/login');
                    console.log(error);
                    return;
                }
            }
            else {
                data = { userId: context.userId };
            }
            // END Check cookie and context


            fetch(`${Constants.SERVER}/user/account`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (resultResponse) => {
                        changeStateLoading(false);
                        if (resultResponse) {
                            // console.log(resultResponse)  ;
                            setName(resultResponse.name);
                            setEmail(resultResponse.email);
                            setDirection(resultResponse.direction);
                            setCountry(resultResponse.country);
                            setPhone(resultResponse.phone);
                            // setResult(`We have sent a confirmation email to ${email}`);
                        }
                        else {
                            setResult("Something went wrong. Please, try again");
                            // alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        setResult("Something went wrong. Please, try again");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Something went wrong. Please, try again");
            console.log("Something went wrong. Please, try again", error);
        }

    }, [])

    function checkName(event) {
        setName(event.target.value);
    }

    function checkDirection(event) {
        setDirection(event.target.value);
    }

    function checkPhone(event) {
        setPhone(event.target.value);
    }

    function checkCountry(event) {
        setCountry(event.target.value);
    }

    function checkEmail(event) {
        setEmail(event.target.value);
    }




    function changeStateLoading(param) {
        context.setLoading(param);
    }
    function sendForm(event) {
        changeStateLoading(true);
        event.preventDefault();
        if (name === "" || direction === "" || email === "" || country === "" || phone === "") {
            setResult('Please complete the form');
            changeStateLoading(false);
            return;
        }

        // event.preventDefault();
        // console.log(name,direction,email,password1,password2);
        try {


            // fetch(`${Constants.SERVER}/new/room?description=${description}`)
            let data = {
                userId: context.userId,
                name: name,
                email: email,
                phone: phone,
                country: country,
                direction: direction
            };
            fetch(`${Constants.SERVER}/user/update`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (resultResponse) => {
                        changeStateLoading(false);
                        if (resultResponse) {
                            // console.log(resultResponse);
                            setName(resultResponse.name);
                            setEmail(resultResponse.email);
                            setDirection(resultResponse.direction);
                            setCountry(resultResponse.country);
                            setPhone(resultResponse.phone);
                            setResult(`Account updated`);
                        }
                        else {
                            setResult("Something went wrong. Please, try again");
                            // alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        setResult("Something went wrong. Please, try again");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Email not sent!");
            console.log("error ocurred fetching data", error);
        }

    }

    return (
        <div>
            {/* <BotonVolver url="/manager/login" /> */}
            {/* <Header/> */}
            <Spinner cargando={context.loading ? true : false} />
            <BotonVolver url={-1} />
            <div className="logging-manager-container">

                <form className="half-width">
                    <h3>Account</h3>

                    <label htmlFor="femail">Email</label>
                    <input disabled="disabled" type="text" id="femail" name="email" placeholder="" value={email} onChange={checkEmail} />

                    <label htmlFor="fname">Name</label>
                    <input type="text" id="fname" name="name" placeholder="" value={name} onChange={checkName} />

                    <label htmlFor="fdirection">Direction</label>
                    <input type="text" id="fdirection" name="direction" placeholder="" value={direction} onChange={checkDirection} />

                    <label htmlFor="fcountry">Country</label>
                    <input type="text" id="fcountry" name="country" placeholder="" value={country} onChange={checkCountry} />

                    <label htmlFor="fphone">Phone</label>
                    <input type="text" id="fphone" name="phone" placeholder="" value={phone} onChange={checkPhone} />



                    <div>{result}</div>
                    <input type="submit" value="Save" onClick={sendForm} />
                </form>

            </div>

        </div>

    )
}