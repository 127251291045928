import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from '../Constants';
import { useNavigate } from 'react-router';
import BotonVolver from '../components/BotonVolver';
import Context from '../Contexto';
import Spinner from '../Spinner';

export default function RecoveryPasswordContainer() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [result, setResult] = useState(null);
    const context = React.useContext(Context);
    // console.log(context.rooms);

    // useEffect(() => {
    //     if(context.userId === ""){
    //         // console.log('dentro');
    //         navigate('/manager');
    //     }
    // }, [])


    function checkEmail(event) {
        setEmail(event.target.value);
    }

    function changeStateRooms(param) {
        context.setRooms(param);
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    function sendForm(event) {
        changeStateLoading(true);
        try {
            event.preventDefault();
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const roomId = urlParams.get('room');
            // fetch(`${Constants.SERVER}/new/room?description=${description}`)
            let data = {
                email: email
            };
            fetch(`${Constants.SERVER}/password/recovery`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result) {
                            changeStateLoading(false);
                            // changeStateRooms(result.rooms);
                            // navigate('/manager');
                            setResult("Email sent, please check your inbox");
                        }
                        else {
                            changeStateLoading(false);
                            setResult("Email not sent!");
                            alert("Something went wrong");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        setResult("Something went wrong");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Email not sent!");
            console.log("error ocurred fetching data", error);
        }

    }

    return (
        <div>
            <BotonVolver url={-1} />
            <div className="logging-manager-container">
                <Spinner cargando={context.loading ? true : false} />
                <form className="half-width">
                    <h3>Password Recovery</h3>

                    <label htmlFor="fname">Email</label>
                    <input type="text" id="fname" name="firstname" placeholder="" value={email} onChange={checkEmail} />
                    <div>{result}</div>
                    <input type="submit" value="Recover" onClick={sendForm} />
                </form>
            </div>

        </div>

    )
}



