import './App.css';
import './style.css'; 
import React from 'react'
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { useState} from 'react';
import LoggingRoom from './LoggingRoom';
import LoggingManager from './LoggingManager';
import Manager from './Manager';
import EditRoom from './EditRoom';
import RoomCommon from './RoomCommon';
import EditTurn from './EditTurn';
import NewTurn from './NewTurn';
import NewRoom from './NewRoom';
import Context from './Contexto';
import RoomCommonMonitor from './RoomCommonMonitor';
import AboutContainer from './about/AboutContainer';
import ContactContainer from './contact/ContactContainer';
import RecoveryPasswordContainer from './app/RecoveryPasswordContainer';
import NewPasswordContainer from './app/NewPasswordContainer'; 
import NewUser from './app/NewUser';
import ConfirmUserCreatedContainer from './app/ConfirmUserCreatedContainer';
import SubscribeContainer from './shop/SubscribeContainer';
import SettingsManagerContainer from './app/SettingsManagerContainer';
import AccountInfo from './app/AccountInfo';
import PaymentsContainer from './app/PaymentsContainer';
import PayContainer from './app/PayContainer';
import PaySuccessContainer from './app/PaySuccessContainer';
import PayNotSuccessContainer from './app/PayNotSuccessContainer';
import TerminosCondiciones from './about/TerminosCondiciones';
import PoliticaPrivacidad from './about/PoliticaPrivacidad';
import PageNotFoundContainer from './app/PageNotFoundContainer';
import AboutContainerEspaniol from './about/AboutContainerEspaniol';
import LoggingRoomError from './LoggingRoomError';


export default function App() {
    const [userId, setUserId] = useState("");
    const [roomSelected, setRoomSelected] = useState(null);
    const [turnSelected, setTurnSelected] = useState(null);
    const [rooms, setRooms] = useState(null);
    const [loading, setLoading] = useState(false);
    const [subscriptionFinished, setSubscriptionFinished] = useState(false);
    
    return (
        <Context.Provider
        value={{
          userId,
          setUserId,
          roomSelected,
          setRoomSelected,
          turnSelected,
          setTurnSelected,


          rooms,
          setRooms,
          loading,
          setLoading,

          subscriptionFinished,
          setSubscriptionFinished,
          
        }}
      >
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoggingRoom/>}/>
                    <Route path="/error" element={<LoggingRoomError/>}/>
                    <Route path="/room" element={<RoomCommon/>} />
                    <Route path="/manager/room/monitor" element={<RoomCommonMonitor/>} />
                    <Route path="/manager/login" element={<LoggingManager/>} />
                    <Route path="/manager" element={<Manager/>} />
                    <Route path="/manager/room/edit" element={<EditRoom/>} />
                    <Route path="/manager/turn/edit" element={<EditTurn/>} />
                    <Route path="/manager/turn/new" element={<NewTurn/>} />
                    <Route path="/manager/room/new" element={<NewRoom/>} />
                    <Route path="/about" element={<AboutContainer/>} />
                    <Route path="/about/es" element={<AboutContainerEspaniol/>} />
                    <Route path="/contact" element={<ContactContainer/>} />
                    <Route path="/password/recovery" element={<RecoveryPasswordContainer/>} />
                    <Route path="/password/new" element={<NewPasswordContainer/>} />
                    {/* <Route path="/user/new" element={<NewUser/>} /> */}
                    <Route path="/user/confirm" element={<ConfirmUserCreatedContainer/>} />
                    <Route path="/subscribe" element={<SubscribeContainer/>} />
                    <Route path="/settings" element={<SettingsManagerContainer/>} />
                    <Route path="/settings/account" element={<AccountInfo/>} />
                    <Route path="/settings/payments" element={<PaymentsContainer/>} />
                    <Route path="/settings/payments/pay" element={<PayContainer/>} />
                    <Route path="/settings/payments/success" element={<PaySuccessContainer/>} />
                    <Route path="/settings/payments/notsuccess" element={<PayNotSuccessContainer/>} />
                    <Route path="/terms" element={<TerminosCondiciones/>} />
                    <Route path="/privacy" element={<PoliticaPrivacidad/>} />

                    {/* Esta ruta tiene que estar la ultima, es para cuando no existe una pagina */}
                    <Route path="*" element={<PageNotFoundContainer/>} /> 
                </Routes>
            </BrowserRouter>
        </div>
    </Context.Provider>
    )
}
















