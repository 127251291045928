import React from 'react'
import Context from '../Contexto';


export default function Row({evenOrOdd, indexTurn }) {
    const context = React.useContext(Context);
    return (
        <div className={`container-row ${evenOrOdd}`} >
            <div className="container-colums-row">
                <div className="left">{context.roomSelected.turns[indexTurn].id}</div>
                <div className="right">{context.roomSelected.turns[indexTurn].door}</div>

            </div>
        </div>
    )
}
