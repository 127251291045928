import React from 'react'
import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Elements, CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import BotonVolver from '../components/BotonVolver';
import { useNavigate } from 'react-router';
import Context from '../Contexto';
import Spinner from '../Spinner';
import Constants from '../Constants';
import Utilities from '../Utilities';



export default function PayContainer() {
    
    const stripePromise = loadStripe(Constants.REACT_APP_STRIPE_PUBLIC_KEY);
    
    const [result, setResult] = useState(null);
    const context = React.useContext(Context);
    const navigate = useNavigate();
 
    return (
        <div className='shop-container'>
            {/* <Header /> */}
            <Spinner cargando={context.loading ? true : false} />
            <BotonVolver url="/settings/payments" />
            <Elements stripe={stripePromise} >
                <FormBuy />
            </Elements>
        </div>
    )
}

function FormBuy(params) {
    const priceDay = 10;
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [direccion, setDireccion] = useState("");
    const [email, setEmail] = useState("");
    const [days, setDays] = useState(60);
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const context = React.useContext(Context);
    const navigate = useNavigate();
    const [result, setResult] = useState(null);

    useEffect(() => {
        changeStateLoading(true);
        let data = {};
        if (context.userId === "") {
            try {
                let cookieUserId = Utilities.getUserIdFromTheCookie();
                if (cookieUserId) {
                    context.setUserId(cookieUserId);
                    data = { userId: cookieUserId };
                }
                else {
                    navigate('/manager/login');
                }

            } catch (error) {
                navigate('/manager/login');
                console.log(error);
                return;
            }
        }
        else {
            data = { userId: context.userId };
        }

        try {

            fetch(`${Constants.SERVER}/user/account`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (resultResponse) => {
                        changeStateLoading(false);
                        if (resultResponse) {
                            // console.log(resultResponse)  ;
                            setName(resultResponse.name);
                            setEmail(resultResponse.email);
                            setCountry(resultResponse.country);
                            setPhone(resultResponse.phone);
                            setDireccion(resultResponse.direction);
                            // setResult(`We have sent a confirmation email to ${email}`);
                        }
                        else {
                            setResult("Something went wrong. Please, try again");
                            // alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        setResult("Something went wrong. Please, try again");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Something went wrong. Please, try again");
            console.log("Something went wrong. Please, try again", error);
        }

    }, [])
    async function sendForm(event) {
        changeStateLoading(true);
        event.preventDefault();

        if (context.userId === "") {

            navigate('/manager/login');
            alert('We have detected that you are not logged in, please login before making any purchase');
            return;
        }


        // if (name === "" || direccion === "" || email === "" || phone === "" || country === "" || phone === ""
        // ||document.getElementById('card-elemnt-liver').classList.contains('StripeElement--empty')) {
        //     setResult('Please complete the form');
        //     alert('Please complete the form');
        //     changeStateLoading(false);
        //     return;
        // }
        try {
            let body = {
                days: days,
                userId: context.userId,
                email: email,
                phone: phone,
                direction: direccion,
                country: country,
            }
            const response = await fetch("http://localhost:4000/pay", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            console.log(data);
            const cardElement = elements.getElement(CardElement);
            const confirmPayment = await stripe.confirmCardPayment(
                data.clientSecret,
                {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: name,
                            email: email,
                            phone: phone,
                            address: {
                                line1: `direction: ${direccion}, country${country}`,

                            },

                        },

                    },

                }
            );
            // console.log(confirmPayment);
            // const { paymentIntent } = confirmPayment;
            // if (paymentIntent.status === "succeeded") alert(`Payment successful!`);
            // else alert(`Payment failed!`);
            console.log(confirmPayment);
            console.log(confirmPayment.paymentIntent);
            if (confirmPayment.error) {
                // Handle error here
                // alert(`Payment failed!`);
                navigate('/settings/payments/notsuccess')
                changeStateLoading(false);

            } else if (confirmPayment.paymentIntent && confirmPayment.paymentIntent.status === 'succeeded') {
                navigate('/settings/payments/success')
                changeStateLoading(false);
            }
        } catch (err) {
            console.error(err);
            navigate('/settings/payments/notsuccess')
            // alert("There was an error in payment");
            changeStateLoading(false);
        }
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }

    function changeNameState(param) {
        setName(param.target.value);
    }

    function changeCountryState(param) {
        setCountry(param.target.value);
    }
    function changeDireccionState(param) {
        setDireccion(param.target.value);
    }
    function changeEmailState(param) {
        setEmail(param.target.value);
    }
    function changePhoneState(param) {
        setPhone(param.target.value);
    }
    function changeDaysState(param) {
        // console.log(param.target.value);
        setDays(param.target.value);
    }
    return (
        <div className='logging-manager-container'>
            <form className="form-card-pay" onSubmit={sendForm}>
                <h3>Add days</h3>

                <label htmlFor="fname">Email</label>
                <input type="text" disabled="disabled" id="femail" name="firstname" placeholder="" value={email} onChange={changeEmailState} />

                <label htmlFor="fname">Name</label>
                <input type="text" id="fname" name="firstname" placeholder="" value={name} onChange={changeNameState} />

                <label htmlFor="fname">Direction</label>
                <input type="text" id="fdireccion" name="firstname" placeholder="" value={direccion} onChange={changeDireccionState} />

                <label htmlFor="fcountry">Country</label>
                <input type="text" id="fcountry" name="country" placeholder="" value={country} onChange={changeCountryState} />

                <label htmlFor="fphone">Phone</label>
                <input type="text" id="fphone" name="phone" placeholder="" value={phone} onChange={changePhoneState} />

                <label htmlFor="fdays">Amount</label>
                {/* <input type="number" id="fdays" name="firstname" placeholder="" value={days} min="30" max="1000" onkeydown="return false" onChange={changeDaysState} /> */}
                <select name="select" onChange={changeDaysState}>
                    <option value="30">30 days</option>
                    <option value="60" selected>60 days</option>
                    <option value="180">180 days</option>
                    <option value="360">360 days</option>
                </select>
                <div className='invoice-container'>
                    <div className='left'>
                        <div>Amount</div>
                        <div>Subtotal</div>
                        <div>Tax</div>
                        <div><b>Total</b></div>
                    </div>
                    <div className='right'>
                        <div><b>{days}</b> days</div>
                        <div>{priceDay * days * 0.79} €</div>
                        <div>{priceDay * days * 0.21} €</div>
                        <div><b>{priceDay * days} €</b></div>
                    </div>
                </div>

                <label htmlFor="fname">Card</label>
                {/* <input type="text" id="fcard" name="firstname" placeholder="" value={email} onChange={changeEmailState} /> */}
                {/* <PaymentElement /> */}
                <div className='card-element' >

                    <CardElement id="card-elemnt-liver" options={{ hidePostalCode: true }}></CardElement>
                </div>
                <div className='red-color'>{result}</div>
                <input type="submit" value="Pay" onClick={sendForm} />
            </form>
        </div>

    )
}
