import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function Header() {
    const navigate = useNavigate();
    useEffect(() => {
    }, [])
    function menuHamburguesa() {
        // console.log('Dentrop');
        var x = document.getElementById("myTopnav");
        // if (x != null) {
        if (x.className === "topnav") {
            x.className += " responsive";
            x.style.display = 'block';
        } else {
            x.className = "topnav";
            x.style.display = 'flex';
        }
        // }

    }
    return (

        <header className='site-header'>
            <div className="topnav" id="myTopnav">
                <a onClick={() => navigate("/about")} className="active" id='logo-about-menu'>liver</a>
                <a onClick={() => navigate("/about")} className="active">About us</a>
                <a onClick={() => navigate("/manager/login")}>App</a>
                {/* Activate route /user/new */}
                {/* <a onClick={() => navigate("/subscribe")}>Join us</a> */}
                <a onClick={() => navigate("/contact")}>Contact</a>
                <a href="#" className="icon" onClick={() => menuHamburguesa()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icono-hamburguesa" viewBox="0 0 512 512"><title>Menu</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="48" d="M88 152h336M88 256h336M88 360h336" /></svg>
                </a>
            </div>
        </header>

    )
}
