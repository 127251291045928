import React from 'react'
import { useNavigate } from 'react-router';
export default function PageNotFoundContainer() {
    const navigate = useNavigate();
    return (
        <div>
            <div className='container-pay-success'>
                <div className='options'>
                    <div >Ups, page not found!</div>
                    {/* <div className='text'><b className="liver-link" onClick={() => navigate(-1)}>Go back!</b></div> */}
                </div>
            </div>
            <a onClick={() => navigate("/about")}><div className="liver-in-loggin-room">more about <b>liver</b></div></a>
        </div>
    )
}
