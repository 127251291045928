import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from '../Constants';
import { useNavigate } from 'react-router';
import BotonVolver from '../components/BotonVolver';
import Context from '../Contexto';
import Spinner from '../Spinner';

export default function NewPasswordContainer() {

    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const context = React.useContext(Context);
    // console.log(context.rooms);

    // useEffect(() => {
    //     if(context.userId === ""){
    //         // console.log('dentro');
    //         navigate('/manager');
    //     }
    // }, [])


    function checkPassword(event) {
        setPassword(event.target.value);
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    function sendForm(event) {
        changeStateLoading(true);
        try {
            event.preventDefault();

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');
            // fetch(`${Constants.SERVER}/new/room?description=${description}`)
            let data = {
                password: password,
                id: id,
            };
            fetch(`${Constants.SERVER}/password/new`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result) {
                            // changeStateLoading(false);
                            // changeStateRooms(result.rooms);
                            // 
                            context.setUserId(result._id.toString());
                            changeStateLoading(false);
                            navigate('/manager');

                        }
                        else {
                            changeStateLoading(false);
                            alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        alert("Ups!, Algo salio mal");
                        console.log(error);
                    }
                )
        } catch (error) {
            alert("Ups!, Algo salio mal");
            console.log("error ocurred fetching data", error);
        }

    } 

    return (
        <div>
            <BotonVolver url="/manager/login" />
            <div className="logging-manager-container">
                <Spinner cargando={context.loading ? true : false} />
                <form className="half-width">
                    <h3>Password Recovery</h3>

                    <label htmlFor="fname">New password</label>
                    <input type="text" id="fname" name="firstname" placeholder="" value={password} onChange={checkPassword} />

                    <input type="submit" value="Password Recovery" onClick={sendForm} />
                </form>
            </div>

        </div>

    )
}



