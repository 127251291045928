export default function Spinner(props) {
        if(props.cargando){
            return(
                <div className = "contenedor-spinner">
                    <div className="spinner"></div>
                </div>
            )   
        }
        else{
           return  <div></div>
        }
}
