import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from '../Constants';
import { useNavigate } from 'react-router';
import BotonVolver from '../components/BotonVolver';
import Context from '../Contexto';
import Spinner from '../Spinner';

export default function ConfirmUserCreatedContainer() {

    const navigate = useNavigate();

    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const context = React.useContext(Context);
    const [problems, setProblems] = useState(false);
    // console.log(context.rooms);

    useEffect(() => {
        // if(context.userId === ""){
        //     // console.log('dentro');
        //     navigate('/manager');
        // }

        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const userId = urlParams.get('id');

            // fetch(`${Constants.SERVER}/new/room?description=${description}`)
            let data = {
                userId: userId
            };
            fetch(`${Constants.SERVER}/user/confirm`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (resultResponse) => {
                        changeStateLoading(false);
                        if (resultResponse) {
                            console.log(resultResponse);
                            setResult('User created');
                            context.setUserId(resultResponse.userId);
                        }
                        else {
                            setResult("Error confirming user!");
                            setProblems(true);
                            // alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        setProblems(true);
                        changeStateLoading(false);
                        setResult("Error confirming user!");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Error confirming user!");
            changeStateLoading(false);
            setProblems(true);
            // console.log("error ocurred fetching data", error);
        }
    }, [])

    function changeStateLoading(param) {
        setLoading(param);
    }



    return (
        <div>
            {/* <BotonVolver url="/manager/login" /> */}
            <div className="logging-manager-container">
                {loading ?
                    <Spinner cargando={true} />
                    :
                    <div className='container-confirm-user'>
                        <div id="text-acces-app-user-confirm">{result}</div>
                        {
                            !problems ?
                                <div id="button-acces-app-user-confirm" onClick={() => navigate('/manager')}>Access</div>
                                :
                                <div id="button-acces-app-user-confirm" onClick={() => navigate('/about')}>About</div>
                        }

                    </div>
                }
            </div>

        </div>

    )
}



