import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from './Constants';
import { useNavigate } from 'react-router';
import BotonVolver from './components/BotonVolver';
import Context from './Contexto';
import Spinner from './Spinner';
import Utilities from './Utilities';

export default function EditTurn() {
    const context = React.useContext(Context);
    const [turn, setTurn] = useState('');
    const [name, setName] = useState('');
    const [door, setDoor] = useState('');
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        if (context.userId === "") {
            // console.log('dentro');
            navigate('/manager');
            return;
        }
        else {
            setTurn(context.turnSelected.id);
            setName(context.turnSelected.name);
            setDoor(context.turnSelected.door);
            setPhone(context.turnSelected.phone);
        }
    }, [])
    function checkDoor(event) {
        setDoor(event.target.value);
    }
    function checkName(event) {
        setName(event.target.value);
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    function checkPhone(event) {
        setPhone(event.target.value);
    }

    function sendForm(event) {
        event.preventDefault();
        try {
            changeStateLoading(true);
            let data = {
                userId: context.userId,
                roomId: context.roomSelected.room,
                turnId: turn,
                name: name,
                door: door,
                phone:phone,
            };
            fetch(`${Constants.SERVER}/edit/turn`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result);
                        if (result) {
                            // alert("Ups, algo ha salido mal");
                            changeStateLoading(false);
                            context.setRoomSelected(result);
                            navigate('/manager/room/edit');

                        }
                        else {
                            alert("Ups, algo ha salido mal");
                            changeStateLoading(false);
                        }

                    },
                    (error) => {
                        alert("Ups, algo ha salido mal");
                        console.log(error);
                        changeStateLoading(false);
                    }
                )

        } catch (error) {
            changeStateLoading(false);
            console.log("Error deleting room", error);
            alert("Ups, algo ha salido mal");
        }

    }

    return (
        <div>
            <BotonVolver url='/manager/room/edit' />
            <div className="logging-manager-container">
                <Spinner cargando={context.loading ? true : false} />
                <form className="half-width">
                    <h3>Turn</h3>

                    <label htmlFor="fname">ID</label>
                    <div className='container-turn-id-print-icon'>
                        <input type="text" disabled id="fname" name="firstname" placeholder="J32" value={turn} />
                        <svg onClick={() => Utilities.printTurn(context.turnSelected.id, context.roomSelected.qr, context.roomSelected.room)} className="print-icon-edit-turn" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M18,7V4c0-0.55-0.45-1-1-1H7C6.45,3,6,3.45,6,4v3H18z" /><path d="M19,8H5c-1.66,0-3,1.34-3,3v5c0,0.55,0.45,1,1,1h3v2c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-2h3c0.55,0,1-0.45,1-1v-5 C22,9.34,20.66,8,19,8z M16,19H8v-4h8V19z M18,12.5c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S18.55,12.5,18,12.5z" /></g></g></svg>

                    </div>

                    <label htmlFor="fname">Door</label>
                    <input type="text" id="fname" name="firstname" placeholder="Door" value={door} onChange={checkDoor} />

                    <label htmlFor="fname">Name</label>
                    <input type="text" id="fname" name="firstname" placeholder="User name" value={name} onChange={checkName} />

                    <label htmlFor="fphone">Phone</label>
                    <input type="text" id="fphone" name="firstname" placeholder="User phone" value={phone} onChange={checkPhone} />

                    <input type="submit" value="Save" onClick={sendForm} />

                </form>
            </div>

        </div>

    )
}



