import React, { useEffect } from "react"
import { useNavigate } from 'react-router';
import Context from './Contexto';

export default function LoggingRoom() {
    const navigate = useNavigate();
    const context = React.useContext(Context);
    let valueForm = "";

    useEffect(() => {
        document.getElementById('room-id-click').click();
    }, [])

    const checkButtonAppears = (event) => {
        valueForm = event.target.value + "";
        if ((event.target.value + "").length > 0) {
            document.getElementById('button-acces-room-user').style.display = 'initial';
        }
    }
    function sendForm(event) {
        event.preventDefault();
        // context.setRoomSelectedNumber(valueForm);
        // navigate(`/room`);
        navigate(`/room?id=${valueForm}`);
    }

    return (
        // <div id = "home-container" onClick = {()=>navigate("/blogs",{ state:{ datos: "SIIII"}})}>
        <div className="loggin-room-container" >
            <form className="half-width">
                <label htmlFor="name" id="room-id-click" >
                    <input className='input-loggin-room' autoComplete="off" type="text" id="name" placeholder="Name" onChange={checkButtonAppears} />
                    <span>What is your room?</span>
                </label>
                <button id="button-acces-room-user" onClick={sendForm}>Go!</button>

            </form>

            <a onClick={()=>navigate("/about")}><div className="liver-in-loggin-room">more about <b>liver</b></div></a>
        </div>
    )
}