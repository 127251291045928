import React from 'react';
import { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router';
import video from '../multimedia/videoEspaniol.mp4'
import Footer from '../components/Footer';
import HeaderEspaniol from '../components/HeaderEspaniol';
export default function AboutContainerEspaniol() {
    const navigate = useNavigate();
    // Cambia la posicion de los elementos cuando se pasa a pantalla pequeña
    // function procesadoPaginaPrincipal() {
    //     cambioOrdenPlanificacion();

    //     function cambioOrdenPlanificacion() {
    //         if (window.matchMedia("(max-width: 769px)").matches) {
    //             var content = document.getElementById('titulo-planificacion1');
    //             var parent = content.parentNode;
    //             parent.insertBefore(content, parent.firstChild); 

    //             content = document.getElementById('titulo-planificacion3');
    //             parent = content.parentNode;
    //             parent.insertBefore(content, parent.firstChild);
    //         }
    //         else {
    //             var content = document.getElementById('texto-planificacion1');
    //             var parent = content.parentNode;
    //             // parent.append
    //             parent.insertBefore(content, parent.firstChild);
    //             var content = document.getElementById('texto-planificacion3');
    //             var parent = content.parentNode;
    //             // parent.append
    //             parent.insertBefore(content, parent.firstChild);
    //         }
    //     }

    //     var mediaqueryList = window.matchMedia("(max-width: 769px)");
    //     mediaqueryList.addListener(function (EventoMediaQueryList) {
    //         cambioOrdenPlanificacion();
    //     });


    // }
    useEffect(() => {
        // procesadoPaginaPrincipal();

    }, [])
    // function menuHamburguesa() {
    //     console.log('Dentrop');
    //     var x = document.getElementById("myTopnav");
    //     // if (x != null) {
    //     if (x.className === "topnav") {
    //         x.className += " responsive";
    //         x.style.display = 'block';
    //     } else {
    //         x.className = "topnav";
    //         x.style.display = 'flex';
    //     }
    //     // }

    // }
    return (
        <div>
            <HeaderEspaniol />
            <div></div>

            <div className="pantalla-completa">
                <div className="pantalla-mitad">
                    <div className="contenedor-foto-portada-izquierda">
                        <div className="transparecia-fondo-izquierda">
                            <div className="texto-portada-izquierda animate__animated animate__slideInUp">

                                <div className="texto-grande-portada-izquierda">

                                    Salas<br /> de espera<br />no saturadas<span className="texto-cambio-color2">.</span>
                                </div>
                                <div className="texto-medio-portada-izquierda">
                                    Los turnos en tu mano.
                                </div>
                                <div className="texto-pequeno-portada-izquierda">
                                    Permite seguir los turnos de llamada desde tu smartphone en tiempo real.
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div className="pantalla-mitad" id="foto-portada-derecha">
                    <div className="contenedor-foto-portada-derecha">

                    </div>
                </div>
            </div>



            <div className="texto-suelto-ancho-completo ">
                <div>
                    <div id="texto1" >
                        La distancia en una sala de espera <br />es algo vital<span className="texto-cambio-color2">.</span>
                    </div><br />
                    <div id="texto2">
                        El paciente puede esperar al aire libre a ser llamado a través de su smartphone.<b></b>
                    </div>
                </div>
            </div>

            <div className='container-iframe'>
                <video className='video' controls>
                    <source src={video} type="video/mp4"/>
                </video>
                {/* <iframe width="800vw" height="450vw" src="https://www.youtube.com/embed/YboJUHtcDS0?fs=1&&modestbranding=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe>  */}
            </div>




            {/* <div className="texto-suelto-ancho-completo">
                <div>
                    <div id="texto1">
                        Cómo lo hacemos<span className="texto-cambio-color2">.</span>
                    </div><br />
                    <div id="texto2">
                        Aplicación que no requiere instalación ni por parte del hospital ni por parte del paciente, funciona a través de un navegador web.
                    </div>
                </div>
            </div> */}


            <div className="foto-texto-planificacion">

                <div className="item-foto-texto-planificacion" id="texto-planificacion1">
                    <div className="contenedor-item-foto-texto-planificacion">
                        <div className="texto7">
                            El usuario administrador podra realizar las siguientes tareas para gestionar la sala.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Añadir y borrar salas.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Añadir y borrar pacientes.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Llamar a un paciente.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Publicar en una pantalla a los pacientes que son llamados.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Publicar en una pantalla un código QR (asociado a la sala).
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Integración de <b>liver</b> con cualquier software mediante API REST.
                        </div>


                    </div>



                </div>
                <div className="item-foto-texto-planificacion" id="titulo-planificacion1">
                    <div className="imagen-texto-planificacion" id="imagen-texto-planificacion1">
                        <div className="texto-dentro-imagen-planificacion">
                            <div className="numero-planificacion">01</div>
                            <div className="texto-numero-planificacion">Control</div>

                        </div>

                    </div>

                </div>


            </div>

            <div className="foto-texto-planificacion">

                <div className="item-foto-texto-planificacion" id="titulo-planificacion2" >
                    <div className="imagen-texto-planificacion" id="imagen-texto-planificacion2">
                        <div className="texto-dentro-imagen-planificacion">
                            <div className="numero-planificacion">02</div>
                            <div className="texto-numero-planificacion">Espera</div>
                        </div>

                    </div>
                </div>

                <div className="item-foto-texto-planificacion" id="texto-planificacion2">
                    <div className="contenedor-item-foto-texto-planificacion">
                        <div className="texto7">
                            Con este sistema el paciente.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Verá el turno en su telefono en tiempo real.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Recibirán un SMS cuando sea su turno.
                        </div>

                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Podra salir al exterior para poder esperar a ser llamado.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Tendra seguridad al acudir a una hospital.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Respetara las distancias sociales.
                        </div>

                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Tendra menor probabilidad de contagio.
                        </div>


                    </div>



                </div>

            </div>

            <div className="foto-texto-planificacion">

                <div className="item-foto-texto-planificacion" id="texto-planificacion3">
                    <div className="contenedor-item-foto-texto-planificacion">
                        <div className="texto7">

                            Gracias a este sistema se controlarán los contagios COVID en lugares cerrados, permitiendo mayor seguridad en las salas de espera.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Salas de espera menos abarrotadas.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Control del los turnos.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Mejor experiencia de los pacientes.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Menos contágios.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Mayor distancia social.
                        </div>
                        <div className="texto8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono-planificacion" viewBox="0 0 512 512"><title>Checkmark</title><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M416 128L192 384l-96-96" /></svg>
                            Menos muertes.
                        </div>

                    </div>



                </div>
                <div className="item-foto-texto-planificacion" id="titulo-planificacion3">
                    <div className="imagen-texto-planificacion" id="imagen-texto-planificacion3">
                        <div className="texto-dentro-imagen-planificacion">
                            <div className="numero-planificacion">03</div>
                            <div className="texto-numero-planificacion">Resultado</div>
                        </div>

                    </div>
                </div>


            </div>




            {/* <div className="fila-caracteristicas-iconos">

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos " >
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Options</title><path d="M64 144h226.75a48 48 0 0090.5 0H448a16 16 0 000-32h-66.75a48 48 0 00-90.5 0H64a16 16 0 000 32zM448 368h-66.75a48 48 0 00-90.5 0H64a16 16 0 000 32h226.75a48 48 0 0090.5 0H448a16 16 0 000-32zM448 240H221.25a48 48 0 00-90.5 0H64a16 16 0 000 32h66.75a48 48 0 0090.5 0H448a16 16 0 000-32z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Personalizada
                            </div>
                            <div className="texto-item-icono">
                                Diseñamos la web para crear una propuesta única y personalizada para tu negocio.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Lock Closed</title><path d="M368 192h-16v-80a96 96 0 10-192 0v80h-16a64.07 64.07 0 00-64 64v176a64.07 64.07 0 0064 64h224a64.07 64.07 0 0064-64V256a64.07 64.07 0 00-64-64zm-48 0H192v-80a64 64 0 11128 0z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                100% Segura
                            </div>
                            <div className="texto-item-icono">
                                Integramos certificados SSL para proteger el intecambio de datos de tus usuarios.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Server</title><path d="M256 428c-52.35 0-111.39-11.61-157.93-31-17.07-7.19-31.69-18.82-43.64-28a4 4 0 00-6.43 3.18v12.58c0 28.07 23.49 53.22 66.14 70.82C152.29 471.33 202.67 480 256 480s103.7-8.67 141.86-24.42C440.51 438 464 412.83 464 384.76v-12.58a4 4 0 00-6.43-3.18c-11.95 9.17-26.57 20.81-43.65 28-46.54 19.39-105.57 31-157.92 31zM464 126.51c-.81-27.65-24.18-52.4-66-69.85C359.74 40.76 309.34 32 256 32s-103.74 8.76-141.91 24.66c-41.78 17.41-65.15 42.11-66 69.69L48 144c0 6.41 5.2 16.48 14.63 24.73 11.13 9.73 27.65 19.33 47.78 27.73C153.24 214.36 207.67 225 256 225s102.76-10.68 145.59-28.58c20.13-8.4 36.65-18 47.78-27.73C458.8 160.49 464 150.42 464 144z" /><path d="M413.92 226c-46.53 19.43-105.57 31-157.92 31s-111.39-11.57-157.93-31c-17.07-7.15-31.69-18.79-43.64-28a4 4 0 00-6.43 3.22V232c0 6.41 5.2 14.48 14.63 22.73 11.13 9.74 27.65 19.33 47.78 27.74C153.24 300.34 207.67 311 256 311s102.76-10.68 145.59-28.57c20.13-8.41 36.65-18 47.78-27.74C458.8 246.47 464 238.41 464 232v-30.78a4 4 0 00-6.43-3.18c-11.95 9.17-26.57 20.81-43.65 27.96z" /><path d="M413.92 312c-46.54 19.41-105.57 31-157.92 31s-111.39-11.59-157.93-31c-17.07-7.17-31.69-18.81-43.64-28a4 4 0 00-6.43 3.2V317c0 6.41 5.2 14.47 14.62 22.71 11.13 9.74 27.66 19.33 47.79 27.74C153.24 385.32 207.66 396 256 396s102.76-10.68 145.59-28.57c20.13-8.41 36.65-18 47.78-27.74C458.8 331.44 464 323.37 464 317v-29.8a4 4 0 00-6.43-3.18c-11.95 9.17-26.57 20.81-43.65 27.98z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Hosting / Servidores
                            </div>
                            <div className="texto-item-icono">
                                Contamos con servidores web seguros, escalables y de alto rendimiento (AWS, Azure, etc).
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fila-caracteristicas-iconos">

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Search</title><path d="M456.69 421.39L362.6 327.3a173.81 173.81 0 0034.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 00327.3 362.6l94.09 94.09a25 25 0 0035.3-35.3zM97.92 222.72a124.8 124.8 0 11124.8 124.8 124.95 124.95 0 01-124.8-124.8z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Posicionamiento SEO
                            </div>
                            <div className="texto-item-icono">
                                Nuestros especialistas se encargan de posicionar tu web en Google y resto de buscadores
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Phone Portrait</title><path d="M336 0H176a64 64 0 00-64 64v384a64 64 0 0064 64h160a64 64 0 0064-64V64a64 64 0 00-64-64zm32 448a32 32 0 01-32 32H176a32 32 0 01-32-32V64a32 32 0 0132-32h11.35a7.94 7.94 0 017.3 4.75A32 32 0 00224 56h64a32 32 0 0029.35-19.25 7.94 7.94 0 017.3-4.75H336a32 32 0 0132 32z" /><path d="M336 48a11.88 11.88 0 00-9.53 4.69A48 48 0 01288 72h-64a48 48 0 01-38.47-19.31A11.88 11.88 0 00176 48a16 16 0 00-16 16v384a16 16 0 0016 16h160a16 16 0 0016-16V64a16 16 0 00-16-16z" /><path d="M336 0H176a64 64 0 00-64 64v384a64 64 0 0064 64h160a64 64 0 0064-64V64a64 64 0 00-64-64zm32 448a32 32 0 01-32 32H176a32 32 0 01-32-32V64a32 32 0 0132-32h11.35a7.94 7.94 0 017.3 4.75A32 32 0 00224 56h64a32 32 0 0029.35-19.25 7.94 7.94 0 017.3-4.75H336a32 32 0 0132 32z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Responsive
                            </div>
                            <div className="texto-item-icono">
                                100% adaptada a todos los dispositivos actuales: PC, móviles y tablets.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Megaphone</title><path d="M48 176v.66a17.38 17.38 0 01-4.2 11.23v.05C38.4 194.32 32 205.74 32 224c0 16.55 5.3 28.23 11.68 35.91A19 19 0 0148 272a32 32 0 0032 32h8a8 8 0 008-8V152a8 8 0 00-8-8h-8a32 32 0 00-32 32zM452.18 186.55l-.93-.17a4 4 0 01-3.25-3.93V62c0-12.64-8.39-24-20.89-28.32-11.92-4.11-24.34-.76-31.68 8.53a431.18 431.18 0 01-51.31 51.69c-23.63 20-46.24 34.25-67 42.31a8 8 0 00-5.15 7.47V299a16 16 0 009.69 14.69c19.34 8.29 40.24 21.83 62 40.28a433.74 433.74 0 0151.68 52.16 26.22 26.22 0 0021.1 9.87 33.07 33.07 0 0010.44-1.74C439.71 410 448 399.05 448 386.4V265.53a4 4 0 013.33-3.94l.85-.14C461.8 258.84 480 247.67 480 224s-18.2-34.84-27.82-37.45zM240 320V152a8 8 0 00-8-8h-96a8 8 0 00-8 8v304a24 24 0 0024 24h52.45a32.66 32.66 0 0025.93-12.45 31.65 31.65 0 005.21-29.05c-1.62-5.18-3.63-11-5.77-17.19-7.91-22.9-18.34-37.07-21.12-69.32A32 32 0 00240 320z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Marketing
                            </div>
                            <div className="texto-item-icono">
                                Te ayudamos a definir, implementar y optimizar tus campañas para aumentar la rentabilidad de tu negocio.
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="fila-caracteristicas-iconos">

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Balloon</title><path d="M391 307.27c32.75-46.35 46.59-101.63 39-155.68A175.82 175.82 0 00231.38 2c-96 13.49-163.14 102.58-149.65 198.58 7.57 53.89 36.12 103.16 80.37 138.74 24.58 19.68 52.31 33.5 78.62 39.68a8 8 0 016 9.22l-4.87 26.38a16.29 16.29 0 001.48 10.57 16 16 0 0014.2 8.61 15.21 15.21 0 002.23-.16l17.81-2.5a2 2 0 012.09 1.14c16.72 36.31 45.46 63.85 82.15 78.36a16 16 0 0021-9.65c2.83-8.18-1.64-17.07-9.68-20.28a118.57 118.57 0 01-59.3-51.88 2 2 0 011.45-3l7.4-1a16.54 16.54 0 0010.08-5.23 16 16 0 002.39-17.8l-12.06-24.23a8 8 0 013.26-10.55c23.59-13.17 46.45-34 64.65-59.73zm-154.9 16.78a16 16 0 01-5.88-1.12c-41.26-16.32-76.3-52.7-91.45-94.94a16 16 0 1130.12-10.8c14.5 40.44 47.27 65.77 73.1 76a16 16 0 01-5.89 30.88z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Optimizada
                            </div>
                            <div className="texto-item-icono">
                                Proceso de mejora continua, enfocándonos en la experiencia de tus clientes y la rentabilidad del negocio.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Extension Puzzle</title><path d="M345.14 480H274a18 18 0 01-18-18v-27.71a31.32 31.32 0 00-9.71-22.77c-7.78-7.59-19.08-11.8-30.89-11.51-21.36.5-39.4 19.3-39.4 41.06V462a18 18 0 01-18 18H87.62A55.62 55.62 0 0132 424.38V354a18 18 0 0118-18h27.71c9.16 0 18.07-3.92 25.09-11a42.06 42.06 0 0012.2-29.92C114.7 273.89 97.26 256 76.91 256H50a18 18 0 01-18-18v-70.38A55.62 55.62 0 0187.62 112h55.24a8 8 0 008-8v-6.48A65.53 65.53 0 01217.54 32c35.49.62 64.36 30.38 64.36 66.33V104a8 8 0 008 8h55.24A54.86 54.86 0 01400 166.86v55.24a8 8 0 008 8h5.66c36.58 0 66.34 29 66.34 64.64 0 36.61-29.39 66.4-65.52 66.4H408a8 8 0 00-8 8v56A54.86 54.86 0 01345.14 480z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Integraciones
                            </div>
                            <div className="texto-item-icono">
                                Integramos los diferentes sistemas y herramientas que necesites (pasarelas de pago, sistemas de reservas, e-mailing, CRM, etc).
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contenedor-fila-caracteristicas-iconos">
                    <div className="item-caracteristicas-iconos">
                        <div className="contenedor-icono">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icono" viewBox="0 0 512 512"><title>Bar Chart</title><path d="M480 496H48a32 32 0 01-32-32V32a16 16 0 0132 0v432h432a16 16 0 010 32z" /><path d="M156 432h-40a36 36 0 01-36-36V244a36 36 0 0136-36h40a36 36 0 0136 36v152a36 36 0 01-36 36zM300 432h-40a36 36 0 01-36-36V196a36 36 0 0136-36h40a36 36 0 0136 36v200a36 36 0 01-36 36zM443.64 432h-40a36 36 0 01-36-36V132a36 36 0 0136-36h40a36 36 0 0136 36v264a36 36 0 01-36 36z" /></svg>
                        </div>
                        <div className="bloque-texto-item-caracteristicas-iconos">
                            <div className="titulo-item-icono">
                                Informes y Estadísticas
                            </div>
                            <div className="texto-item-icono">
                                Integramos herramientas de Analítica en tu pagina web para que tengas acceso a datos e informes sobre tus visitas.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 */}



            {/* <div className="texto-suelto-ancho-completo-color">
                <div>
                    <div id="texto3">
                        Queremos contar contigo<span className="texto-cambio-color2">.</span>
                    </div>
                    <a id="boton-contactar" onClick={() => navigate("/contact")}>Unete!</a>
                </div>
            </div> */}


            {/* <div className="foto-texto-mitad">

                <div className="item-foto-texto-mitad">
                    <img className="imagen-adaptada-contenedor" src="multimedia/foto6.png" />
                </div>

                <div className="item-foto-texto-mitad">
                    <div className="texto4">
                        Nuestros expertos se encargarán de asesorarte y desarrollar la plataforma perfecta para satisfacer tus necesidades. Desde los proyectos más básicos a los más complejos.
                    </div>

                    <div className="texto5">
                        Desde cero a medida
                    </div>
                    <div className="texto6">
                        Desarrollando front y backend con las últimas tecnologías.
                    </div>
                    <div className="texto5">
                        Web Corporativa
                    </div>
                    <div className="texto6">
                        Utilizando un gestor de contenidos como Wordpress, Wix, etc.
                    </div>
                    <div className="texto5">
                        Landing page (micro-web)
                    </div>
                    <div className="texto6">
                        Perfectamente optimizada para la captación de leads.
                    </div>
                    <div className="texto5">
                        Landbots
                    </div>
                    <div className="texto6">
                        Interfaces conversacionales para sustituir a formularios.
                    </div>
                    <div className="texto5">
                        Blog
                    </div>
                    <div className="texto6">
                        Comunícate con tus clientes y posiciona tu web.
                    </div>

                </div>

            </div> */}


            {/* <div className="foto-suelta-contenedor">
                    <img className = "item-foto-suelta" src="multimedia/foto1.jpeg"/>
                    </div>  */}





            {/* <div className="fotos-tecnologias">
                <img className = "item-fotos-tecnologias" src="multimedia/wordpress.jpg"/>
                <img className = "item-fotos-tecnologias" src="multimedia/node.jpg"/>
                <img className = "item-fotos-tecnologias" src="multimedia/mongo.jpg"/>
                <img className = "item-fotos-tecnologias" src="multimedia/angular.jpg"/>
                </div>  */}

            {/* <div className="texto-suelto-ancho-completo-color">
                <div>
                    <div id="texto3">
                        Queremos trabajar contigo<span className="texto-cambio-color2">.</span>
                    </div>
                    <a id="boton-contactar" href="#">Cuéntanos tu idea</a>
                </div>
            </div> */}


            {/* <div className="texto-suelto-trabajos-realizados">
                <div>
                    <div className="texto9">
                        Ellos lo hicieron<span className="texto-cambio-color1">.</span>
                    </div>
                    <div className="fotos-realizados">
                        <img className="item-fotos-realizados" src="multimedia/entec.png" />
                        <img className="item-fotos-realizados" src="multimedia/qpv.png" />

                    </div>
                </div>
            </div> */}




            <Footer />



        </div>

    )
}
