import React from 'react'
import BotonVolver from '../components/BotonVolver'
import { useNavigate } from 'react-router';
import Context from '../Contexto';
import { useState, useEffect } from 'react';
import Utilities from '../Utilities';


export default function PaySuccessContainer() {
    const navigate = useNavigate();
    const context = React.useContext(Context);
    useEffect(() => {
        context.setSubscriptionFinished(false);
        if (context.userId === "") {
            try {
                let cookieUserId = Utilities.getUserIdFromTheCookie();
                if (cookieUserId) {
                    context.setUserId(cookieUserId);
                    return;
                }
                else {
                    navigate('/manager/login');
                    return
                }

            } catch (error) {
                navigate('/manager/login');
                console.log(error);
                return;
            }
        }
    }, [])
    return (
        <div>
            <BotonVolver url='/settings' />
            <div className='container-pay-success'>
                <div className='options'>
                    <div >Payment successful!</div>
                    <div className='text'>It may take a few minutes for your account to update. <b className = "liver-link" onClick={()=>navigate('/manager')}>Go to liver!</b></div>
                    {/* <div onClick={() => navigate('/settings/account')} className='item'>Account</div>
                    <div onClick={() => navigate('/settings/payments')} className='item'>Payments</div>
                    <div onClick={() => navigate('/password/recovery')} className='item'>Password</div>
                    <div onClick={() => navigate('/contact')} className='item'>Issues</div>
                    <div onClick={() => navigate('/manager/login')} className='item'>Log out</div> */}
                </div>


            </div>
        </div>
    )
}
