import React from 'react';
import { useNavigate } from 'react-router';

export default function Footer() {
    const navigate = useNavigate();
    return (
        <div className="footer">
            {/* <div className='line'></div> */}
            <div>
                <div className="item-footer" onClick={()=>navigate('/about')}>
                    liver
                </div>
                <div className="copyright">
                    Copyright &#169; 2021 liver
                </div>
                <div className="copyright">
                    <a onClick={() => navigate('/terms')}><b>Terms of Service</b></a>  and <a onClick={() => navigate('/privacy')}><b>Privacy Policy</b></a>
                </div>
            </div>

                {/* <div className='line'></div> */}
            </div>
    )
}
