import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

export default function PoliticaPrivacidad() {
    return (
        <div className='terms-container'>
            <Header />
            <div className="terms-text-container" >
                <h1>Política de privacidad de liver.es</h1>
                <div>
                    Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o hace una compra en liver.es (denominado en lo sucesivo el “Sitio”).
                </div>
                <h2>INFORMACIÓN PERSONAL QUE RECOPILAMOS</h2>
                <div>
                    Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información sobre las páginas web individuales o los productos que ve, las páginas web o los términos de búsqueda que lo remitieron al Sitio e información sobre cómo interactúa usted con el Sitio. Nos referimos a esta información recopilada automáticamente como “Información del dispositivo”.

                    Recopilamos Información del dispositivo mediante el uso de las siguientes tecnologías:
                </div>
                <h2> COOKIES</h2>
                <div>
                    Aquí tienes una lista de cookies que utilizamos. Las enlistamos para que puedas elegir si quieres optar por quitarlas o no.

                    store, permite a liver.es guardar información de la sesión, tiene una persistencia de 1 diía.


                </div>

                <div>

                    Además, cuando hace una compra o intenta hacer una compra a través del Sitio, recopilamos cierta información de usted, entre la que se incluye su nombre, dirección de facturación, dirección de envío, información de pago (incluidos los números de la tarjeta de crédito, dirección de correo electrónico y número de teléfono.  Nos referimos a esta información como “Información del pedido”.



                    Cuando hablamos de “Información personal” en la presente Política de privacidad, nos referimos tanto a la Información del dispositivo como a la Información del pedido.
                </div>
                <h2>¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</h2>
                <div>
                    La información personal relacionada con las salas de espera es unicamente gestionada por el cliente(cuando el cliente borra una sala de espera o un turno, esta información es borrada de nuestros servidores), esta información únicamente se usa en la aplicación web para mostrar y gestionar las salas de espera.
                    Usamos la Información del pedido que recopilamos en general para preparar los pedidos realizados a través del Sitio (incluido el procesamiento de su información de pago, la organización de los envíos y la entrega de facturas y/o confirmaciones de pedido).  Además, utilizamos esta Información del pedido para: comunicarnos con usted;
                    examinar nuestros pedidos en busca de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted compartió con nosotros, le proporcionamos información o publicidad relacionada con nuestros productos o servicios.
                    Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estadísticas sobre cómo nuestros clientes navegan e interactúan con el Sitio y para evaluar el éxito de nuestras campañas publicitarias y de marketing).

                    Compartimos su Información personal con terceros para que nos ayuden a utilizar su Información personal, tal como se describió anteriormente.  Por ejemplo, utilizamos la tecnología de https://stripe.com/ para realizar los pagos de forma segura.

                    Finalmente, también podemos compartir su Información personal para cumplir con las leyes y regulaciones aplicables, para responder a una citación, orden de registro u otra solicitud legal de información que recibamos, o para proteger nuestros derechos.
                </div>
                <h1>NO RASTREAR</h1>
                <div>Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear desde su navegador.
                </div>
                <h2>SUS DERECHOS</h2>
                <div>Si usted es un residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personal sea corregida, actualizada o eliminada. Si desea ejercer este derecho, comuníquese con nosotros a través de la información de contacto que se encuentra a continuación.
                    Además, si usted es un residente europeo, tenemos en cuenta que estamos procesando su información para cumplir con los contratos que podamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio) o para perseguir nuestros intereses comerciales legítimos enumerados anteriormente.  Además, tenga en cuenta que su información se transferirá fuera de Europa, incluidos Canadá y los Estados Unidos.
                </div>

                <h2>RETENCIÓN DE DATOS</h2>
                <div>
                    Cuando realiza un pedido a través del Sitio, mantendremos su Información del pedido para nuestros registros a menos que y hasta que nos pida que eliminemos esta información.
                </div>

                <h2>MENORES</h2>
                <div>El sitio no está destinado a personas menores de 18 años.</div>

                <h2>CAMBIOS</h2>
                <div>Podemos actualizar esta política de privacidad periódicamente para reflejar, por ejemplo, cambios en nuestras prácticas o por otros motivos operativos, legales o reglamentarios.
                </div>
                <h1>CONTÁCTENOS</h1>
                <div>
                    Para obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, contáctenos por correo electrónico a joseyaguegregorio@gmail.com o por correo mediante el uso de la información que se proporciona a continuación:
                </div>
                Calle Mesena 108, Madrid, M, 28033, España

            </div>

            <Footer />
        </div >
    )
}
