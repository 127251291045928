import React, { useEffect } from "react"
import { useNavigate } from 'react-router';
import Context from './Contexto';

export default function LoggingRoomError() {
    const navigate = useNavigate();
    const context = React.useContext(Context);
    let valueForm = "";

    useEffect(() => {
    }, [])



    return (
        // <div id = "home-container" onClick = {()=>navigate("/blogs",{ state:{ datos: "SIIII"}})}>
        <div className="loggin-room-container" >
            <div className="error-container-center">
                <div>
                    <div className='title-room-not-exist'>Ups, this room does not exist</div>
                    <div className="try-again" onClick={()=>navigate('/')}>Try again!</div>
                </div>
            </div>


            <a onClick={() => navigate("/about")}><div className="liver-in-loggin-room">more about <b>liver</b></div></a>
        </div>
    )
}