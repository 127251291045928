import React from 'react'
import BotonVolver from '../components/BotonVolver'
import { useNavigate } from 'react-router';
import Context from '../Contexto';
import { useState, useEffect } from 'react';
import Spinner from '../Spinner';
import Constants from '../Constants';
import Utilities from '../Utilities';

export default function PaymentsContainer() {
    const navigate = useNavigate();
    const context = React.useContext(Context);
    const [loading, setLoading] = useState(true);
    const [days, setDays] = useState("");
    const [date, setDate] = useState("");
    useEffect(() => {

        // BEGINING Check cookie and context
        let data = {};
        if (context.userId === "") {
            try {
                let cookieUserId = Utilities.getUserIdFromTheCookie();
                if (cookieUserId) {
                    data = { userId: cookieUserId };
                    context.setUserId(cookieUserId);
                }
                else {
                    navigate('/manager/login');
                }

            } catch (error) {
                navigate('/manager/login');
                console.log(error);
                return;
            }
        }
        else {
            data = { userId: context.userId };
        }
        // END Check cookie and context

        try {
            // let data = {
            //     userId: context.userId
            // };
            fetch(`${Constants.SERVER}/user/account`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (resultResponse) => {
                        changeStateLoading(false);
                        console.log(resultResponse);
                        if (resultResponse) {
                            setDays(Utilities.checkSubscriptionDays(resultResponse.subscriptionTimestamp))
                            let allDate = resultResponse.subscriptionDate.split('GMT');
                            setDate(allDate[0]);
                        }
                        else {

                        }

                    },
                    (error) => {
                        changeStateLoading(false);

                        console.log(error);
                    }
                )
        } catch (error) {
            changeStateLoading(false);

            console.log("error ocurred fetching data", error);
        }
    }, [])



    function changeStateLoading(param) {
        setLoading(param);
    }

    return (
        <div>
            <BotonVolver url='/settings' />
            <div className='container-payments'>
                {loading ?
                    <Spinner cargando={true} />
                    :
                    <div className='options'>

                        <div className='text'><b className={days <= 0 ? "red-color" : ""}>{days}</b> days left</div>
                        <div className='date'>Your subscription {days >= 0 ? "finish" : "finished"} on {date}</div>
                        {/* <div className='date'>{date}</div> */}
                        <div onClick={() => navigate('/settings/payments/pay')} className='item'><b>Add</b> more</div>
                    </div>
                }
            </div>

        </div>
    )
}
