import React from 'react'
import { useNavigate } from 'react-router';
import Constants from '../Constants';
import Context from '../Contexto';

export default function RowEditable({ evenOrOdd, indexTurn }) {
  const navigate = useNavigate();
  const context = React.useContext(Context);

  function callTurn(turn) {
    console.log(turn);
    // // navigate('/manager/room/edit?id=1');
    // const queryString = window.location.search;
    // // console.log(queryString);
    // const urlParams = new URLSearchParams(queryString);
    // const idRoom = urlParams.get('id');
    // fetch(`${Constants.SERVER}/notify?room=${idRoom}&turn=${turn}`)

    function changeRoomState(param) {
      context.setRoomSelected(param);
      context.setLoading(false);
    }

    let data = {
      userId: context.userId,
      roomId: context.roomSelected.room,
      turnId: turn,
      phone:context.roomSelected.turns[indexTurn].phone,
      door:context.roomSelected.turns[indexTurn].door,
      active: !context.roomSelected.turns[indexTurn].active
    };

    context.setLoading(true);
    fetch(`${Constants.SERVER}/notify`, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          // if(result.status){
          //     navigate('/manager/room/edit?id=1');
          // }
          // else{
          //     // alert("User or password incorrect, please try again");
          // }
          changeRoomState(result);

        },
        (error) => {
          console.log("eureca", error);
          alert('Algo ha salido mal, por favor vuelva a intentarlo');

        }
      )
  }

  function deleteTurn(turnId) {
    try {
      context.setLoading(true);
      let data = {
        userId: context.userId,
        roomId: context.roomSelected.room,
        turnId: turnId,
      };
      fetch(`${Constants.SERVER}/delete/turn`, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result);
            context.setRoomSelected(result);
            context.setLoading(false);
          },
          (error) => {
            console.log(error);
            context.setLoading(false);
          }
        )
    } catch (error) {
      console.log('Error deleting turn', error);
      alert('Algo ha salido mal, por favor vuelva a intentarlo');
      context.setLoading(false);
    }


  }

  function editTurn(turnId) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const idRoom = urlParams.get('id');
    // context.setRoomSelected(context.roomSelected.turns[indexTurn].room);
    context.setTurnSelected(context.roomSelected.turns[indexTurn]);
    navigate(`/manager/turn/edit`);
  }



  return (
    <div className={`container-row ${evenOrOdd}`} >
      <div className="container-colums-row-editable">
        <div className="left">{context.roomSelected.turns[indexTurn].id}</div>
        <div className="right">{context.roomSelected.turns[indexTurn].door}</div>
        <div className="name">{context.roomSelected.turns[indexTurn].name}</div>
        <Button indexTurn={indexTurn} active={context.roomSelected.turns[indexTurn].active ? "active" : ""} icono={<svg onClick={() => callTurn(context.roomSelected.turns[indexTurn].id)} className="fill" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" /></svg>} />
        <Button indexTurn={indexTurn} icono={<svg onClick={() => editTurn(context.roomSelected.turns[indexTurn].id)} className="fill" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>} />
        <Button indexTurn={indexTurn} icono={<svg onClick={() => deleteTurn(context.roomSelected.turns[indexTurn].id)} className="fill" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" /></svg>} />
      </div>

    </div>
  )
}

function Button(params) {
  const context = React.useContext(Context);
  return (
    <div className="boton-pequenio-editable" >
      <div className="contenedor-centrado-icono">
        <div className={`contenedor-icono ${params.active}`}>
          {params.icono}
        </div>
      </div>
    </div>
  )
} 