import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from './Constants';
import { useNavigate } from 'react-router';
import BotonVolver from './components/BotonVolver';
import Context from './Contexto';
import Spinner from './Spinner';

export default function NewRoom() {

    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    const context = React.useContext(Context);

    useEffect(() => {
        if(context.userId === ""){
            navigate('/manager');
        }
    }, [])


    function checkDescription(event) {
        setDescription(event.target.value);
    }

    function changeStateRooms(param) {
        context.setRooms(param);
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    function sendForm(event) {
        changeStateLoading(true);
        try {
            event.preventDefault();
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const roomId = urlParams.get('room');
            // fetch(`${Constants.SERVER}/new/room?description=${description}`)
            let data = {
                userId: context.userId,
                description: description
            };
            fetch(`${Constants.SERVER}/new/room`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log(result);
                        if (result) {
                            // changeStateLoading(false);
                            // changeStateRooms(result.rooms);
                            navigate('/manager');
                        }
                        else {
                            changeStateLoading(false);
                            alert("Ups!, Algo salio mal");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        alert("Ups!, Algo salio mal");
                        console.log(error);
                    }
                )
        } catch (error) {
            alert("Ups!, Algo salio mal");
            console.log("error ocurred fetching data", error);
        }

    }

    return (
        <div>
            <BotonVolver />
            <div className="logging-manager-container">
                <Spinner cargando={context.loading ? true : false} />
                <form className="half-width">
                    <h3>Room</h3>

                    <label htmlFor="fname">Description</label>
                    <input type="text" id="fname" name="firstname" placeholder="" value={description} onChange={checkDescription} />

                    <input type="submit" value="Create" onClick={sendForm} />
                </form>
            </div>

        </div>

    )
}



