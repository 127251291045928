import React from 'react'
import { useNavigate } from 'react-router';
import Context from '../Contexto';
export default function ButtonRoomManager(params) {
    
    const navigate = useNavigate();
    const context = React.useContext(Context);
  
  return(
      <div className = {`boton-pequenio ${params.extraClass}`} onClick = {()=>params.onClick()}>
          <div className = "contenedor-centrado-icono">
              <div className = "contenedor-icono">{params.icono}</div>
          </div> 
          <div className = "texto">{params.texto}</div>   
      </div>
  )
} 
