import BotonBorrarImagen from './components/LogOutButton'
import BotonVolver from './components/BotonVolver'
import RoomManager from './components/RoomManager'
import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router';
import Constants from './Constants';
import uuid from 'react-uuid'
import Context from './Contexto';
import Spinner from './Spinner';
import Utilities from './Utilities';

export default function Manager() {
    const navigate = useNavigate();
    const context = React.useContext(Context);

    function changeStateLoading(value) {
        context.setLoading(value);
    }

    let cont = 0;
    useEffect(() => {
        
        try {
            let data = {};
            if (context.userId === "") {
                try {
                    let cookieUserId = Utilities.getUserIdFromTheCookie();
                    if (cookieUserId) {
                        context.setUserId(cookieUserId);
                        data = { userId: cookieUserId };
                    }
                    else{
                        navigate('/manager/login');
                        return
                    }
                } catch (error) {
                    navigate('/manager/login');
                    console.log(error);
                    return;

                }

            }
            else {
                data = { userId: context.userId };
            }

            if(context.subscriptionFinished){
                navigate('/settings/payments')
                return
            }
            // let data = { userId: context.userId };
            // console.log("3");
            fetch(`${Constants.SERVER}/rooms`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        context.setRooms(result);
                        // context.setLoading(false);
                        changeStateLoading(false);
                    },
                    (error) => {
                        console.log(error);
                        navigate('/manager/login');
                    }
                )

        } catch (error) {
            console.log(error);
            navigate('/manager/login');
        }

    }, [])
    if (context.rooms !== null) {
        return (
            <div className="container-manager">
                {/* <BotonVolver url='/manager/login' /> */}
                <BotonBorrarImagen />
                <Spinner cargando={context.loading ? true : false} />
                <div className="container-2-manager">
                    <div className={`container-row margin-botom`} onClick={() => navigate('/manager/room/new')}>
                        <div className="container-colums-row-editable new">+</div>
                    </div>

                    {context.rooms.map((item, i) => {
                        cont += 1;
                        if (cont % 2 === 0) {
                            return <RoomManager key={uuid()} indexRoom={i} room={item} evenOrOdd="even" />
                        }
                        else {
                            return <RoomManager key={uuid()} indexRoom={i} room={item} evenOrOdd="odd" />
                        }
                    })}
                </div>
            </div>
        )
    }
    else {
        return <div><Spinner cargando={true} /></div>
    }

}



