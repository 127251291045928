import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router';
import uuid from 'react-uuid'
import sound from './multimedia/sound.mp3'
import ToggleButton from './components/ToggleButton';
import RowEditable from './components/RowEditable';
import BotonVolver from './components/BotonVolver';
import Context from './Contexto';
import Constants from './Constants';
import Spinner from './Spinner';

function EditRoom() {
    let cont = 0;
    const [response, setResponse] = useState(null);
    const context = React.useContext(Context);
    const navigate = useNavigate();



    useEffect(() => {
        if(context.userId === ""){
            navigate('/manager');
            return;
        }
        if(context.subscriptionFinished){
            navigate('/settings/payments')
            return
        }
        try {
            let data = {
                userId: context.userId,
                roomId: context.roomSelected.room
            };

            fetch(`${Constants.SERVER}/room`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        // console.log('Aqui');
                        // console.log(result);
                        // setResponse(result);
                        context.setRoomSelected(result);
                    },
                    (error) => {
                    }
                )


        } catch (error) {
            console.log(error);
            alert('Ups! Algo ha salido mal');
        }



    }, []);

    function navigateNewTurn() {
        navigate(`/manager/turn/new`);
    }

    if (context.roomSelected === null) {
        return <div><Spinner cargando={true} /></div>
    }
    else if (context.roomSelected.turns.length === 0) {
        return (
            <div className="container-edit-room">
                <BotonVolver url = '/manager'/>
                {/* <BotonBorrarImagen/> */}
                <Spinner cargando={context.loading ? true : false} />
                <div className="container-edit-room-rows">
                    <div className={`container-row margin-botom`} >
                        <div className="container-colums-row-editable new" onClick={() => navigateNewTurn()}>+</div>
                    </div>
                </div>
            </div>
        )
    }

    else {

        return (
            <div className="container-edit-room">
                <BotonVolver url='/manager' />
                <Spinner cargando={context.loading ? true : false} />
                <div className="container-edit-room-rows">
                    <div className={`container-row margin-botom`} >
                        <div className="container-colums-row-editable new" onClick={() => navigateNewTurn()}>+</div>
                    </div>
                    {context.roomSelected.turns.map((item, i) => {
                        // console.log(i);
                        cont += 1;
                        if (cont % 2 === 0) {
                            return <RowEditable key={uuid()} indexTurn={i} evenOrOdd="even" />
                        }
                        else {
                            return <RowEditable key={uuid()} indexTurn={i} evenOrOdd="odd" />
                        }
                    })}
                </div>
            </div>

        )
    }
}

export default EditRoom;
