import { useNavigate } from 'react-router';

const BotonVolver = (params) =>{
    const navigate = useNavigate();
    const handleClick = () => {
        console.log(params.url)
        if(params.url){
            navigate(params.url);
        }
        else{
            navigate(-1);
        }
        
    }
    return (
        <div className = "contenedor-boton-volver" onClick={() => handleClick()}><svg className = "icono-boton-volver" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg></div>
    )
}
export default BotonVolver;