import React from 'react'
import Header from '../components/Header'
import Context from '../Contexto';
import Constants from '../Constants';
import { useState } from 'react';
import Spinner from '../Spinner';

export default function ContactContainer() {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("second");

    const [result, setResult] = useState(null);
    const context = React.useContext(Context);

    function sendForm(event) {
        changeStateLoading(true);
        try {
            event.preventDefault();

            let data = {
                name: name,
                phone: phone,
                email: email,
                message: message
            };
            fetch(`${Constants.SERVER}/contact`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result.success) {
                            changeStateLoading(false);
                            // changeStateRooms(result.rooms);
                            // navigate('/manager');
                            setResult("Email sent");
                        }
                        else {
                            changeStateLoading(false);
                            setResult("Email not sent!");
                            // alert("Something went wrong");
                        }

                    },
                    (error) => {
                        changeStateLoading(false);
                        setResult("Something went wrong");
                        console.log(error);
                    }
                )
        } catch (error) {
            setResult("Email not sent!");
            console.log("error ocurred fetching data", error);
        }

    }

    function checkName(event) {
        setName(event.target.value);
    }

    function checkPhone(event) {
        setPhone(event.target.value);
    }

    function checkMessage(event) {
        setMessage(event.target.value);
    }

    function checkEmail(event) {
        setEmail(event.target.value);
    }

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    return (
        <div>
            <Header />
            <div className="formulario-centrado">
                <Spinner cargando={context.loading ? true : false} />
                <div className="opacidad-formulario-fondo">
                    <div className="contenedor-formulario animate__animated animate__swing" >
                        <form action="/formulario" method="post">
                            <label for="fname">Name</label>
                            <input type="text" id="fname" name="nombre" placeholder="Nombre.." onChange={checkName} />

                            <label for="lname">Email</label>
                            <input type="text" id="lname" name="email" placeholder="email@email.com" onChange={checkEmail} />

                            <label for="lname">Phone</label>
                            <input type="text" id="lname" name="telefono" placeholder="666 666 666" onChange={checkPhone} />

                            <label for="subject">Message</label>
                            <textarea id="subject" name="cuerpo" placeholder="Message.." style={{ height: "200px" }} onChange={checkMessage}></textarea>
                            <div className='result-new-user-form'>{result}</div>
                            <div class="contenedor-boton-formulario"><input type="submit" value="Submit" onClick={sendForm} /></div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
