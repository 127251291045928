import React from 'react'
import BotonVolver from '../components/BotonVolver'
import { useNavigate } from 'react-router';
import Context from '../Contexto';
import { useState, useEffect } from 'react';
import Utilities from '../Utilities';


export default function SettingsManagerContainer() {
    const navigate = useNavigate();
    const context = React.useContext(Context);
    useEffect(() => {
        try {
            let cookieUserId = Utilities.getUserIdFromTheCookie();
            if (cookieUserId) {
                context.setUserId(cookieUserId);
            }
            else {
                navigate('/manager/login');
            }

        } catch (error) {
            navigate('/manager/login');
            console.log(error);
            return;
        }
    }, [])


    function logOut() {
        Utilities.deleteCookie('store');
        navigate('/manager/login');
    }
    return (
        <div>
            <BotonVolver url='/manager'/>
            <div className='container-settings'>
                <div className='options'>
                    <div onClick={() => navigate('/settings/account')} className='item'>Account</div>
                    <div onClick={() => navigate('/settings/payments')} className='item'>Payments</div>
                    <div onClick={() => navigate('/password/recovery')} className='item'>Password</div>
                    <div onClick={() => navigate('/contact')} className='item'>Issues</div>
                    <div onClick={() => logOut()} className='item'>Log out</div>
                    {/* BORRAR cookie */}
                </div>


            </div>
        </div>
    )
}
