import React from 'react'
import Header from '../components/Header'
import { useNavigate } from 'react-router';

export default function SubscribeContainer() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <div className="formulario-centrado">
                <div className="container-subscription">
                    <div>
                        Firstly, we are going to create a user account
                    </div>
                    <div className='buttons'>
                        <div className='small'>
                            <div className='create' onClick={() => navigate('/user/new')}>
                                Create
                            </div>
                        </div>

                        <div className='next' onClick={() => navigate('/settings/payments')}>
                            <div>I`m a <b> liver</b> user</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
