
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};


function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

function deleteCookie(nameCookie) {
  document.cookie = `${nameCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}


function getUserIdFromTheCookie() {
  try {
    let cookie = JSON.parse(getCookie("store"))
    // console.log('dentro');
    console.log(cookie.userId);

    // Promise.resolve(cookie.userId);
    return cookie.userId;
  } catch (error) {
    console.log(error);
    return null;
  }

}


function checkSubscriptionStatus(subscriptionFinish) {
  let time = parseInt(subscriptionFinish, 10) - Date.now();
  const dayInMillisecons = 1000 * 60 * 60 * 24;
  let days = time / dayInMillisecons;
  let daysAllowedWithoutSubscription = 15;
  if (days <= (daysAllowedWithoutSubscription * -1)) {
    return false
  }
  else {
    return true;
  }
}
function checkSubscriptionDays(subscriptionFinish) {
  let time = parseInt(subscriptionFinish, 10) - Date.now();
  const dayInMillisecons = 1000 * 60 * 60 * 24;
  let days = time / dayInMillisecons;
  // console.log(parseInt(days,10));
  return parseInt(days, 10);
}
function printTurn(turnId, qr, roomId) {
  
  // console.log();
  var a = window.open('', '', 'height=550, width=550');
  
  a.document.write(`
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset=UTF-8>
    </head>
      <body> 
      <div style="display: flex;align-items: center;font-size: 25vw;flex-direction: column;">
        <div><b>${turnId}</b></div>
        <div style="  display: flex;justify-content: center;align-items: center;">
            <img style="width:100vw"
                src="${qr}" />
        </div>
        <div style="font-size:10vw;text-align: center;">
            <div><b>liver.es</b></div>
            <div> Room ${roomId}</div>
        </div>
      </div>
      </body>
    </html>
    `);


// Si no se pone el delay en ocasiones no le da tiempo a cargar el qr
  setTimeout(() => callback(), 300);

  function callback() {
    a.document.close();
    a.print();
    a.close();
  }


}


module.exports = {
  setCookie,
  getCookie,
  checkSubscriptionStatus,
  checkSubscriptionDays,
  getUserIdFromTheCookie,
  deleteCookie,
  printTurn,


}