import React from 'react'
import ButtonRoomManager from './ButtonRoomManager'
import { useNavigate } from 'react-router';
import Context from '../Contexto';
import Constants from '../Constants';
import Spinner from '../Spinner';

export default function RoomManager(params) {
    const context = React.useContext(Context);
    const navigate = useNavigate();
    // console.log(params.room.turns.length);
    let extraClass = "";
    if (params.room.turns.length === 0) {
        extraClass = "disable-button";
    }
    function editRoom() {
        context.setRoomSelected(params.room);
        // context.setRoom
        navigate(`/manager/room/edit`)
    }
    function shareTvRoom() {
        context.setRoomSelected(params.room);
        // not work becouse when uses window.open the state is deleted and the newt view uses context.roomSelected.
        // window.open('/manager/room/monitor');
        navigate(`/manager/room/monitor`)
    }

    function changeStateRooms(param) {
        context.setRooms(param);
    }
    function deleteRoom() {
        try {
            context.setLoading(true);
            let data = {
                userId: context.userId,
                roomId: context.rooms[params.indexRoom].room
            };
            fetch(`${Constants.SERVER}/delete/room`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if (result) {
                            // alert("Ups, algo ha salido mal");
                            context.setLoading(false);
                            changeStateRooms(result.rooms);
                        }
                        else {
                            alert("Ups, algo ha salido mal");
                            context.setLoading(false);
                        }

                    },
                    (error) => {
                        alert("Ups, algo ha salido mal");
                        context.setLoading(false);
                    }
                )
            // navigate(`/manager/delete?id=${params.room.room}`)
        } catch (error) {
            console.log("Error deleting room", error);
            alert("Ups, algo ha salido mal");
        }

    }
    return (
        <div className={`container-room-manager ${params.evenOrOdd}`}>
            <div className='text'>{context.rooms[params.indexRoom].room}</div>
            <div className='text'>{context.rooms[params.indexRoom].description}</div>
            <div className="bloque4">
                <ButtonRoomManager onClick={() => editRoom()} texto="" icono={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /></svg>} />
                <ButtonRoomManager onClick={() => shareTvRoom()} extraClass={extraClass} texto="" icono={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M21 3H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v1c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-1h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 14H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm-2-9H9c-.55 0-1 .45-1 1s.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1zm0 4H9c-.55 0-1 .45-1 1s.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1zM7 8H5v2h2V8zm0 4H5v2h2v-2z" /></svg>} />
                <ButtonRoomManager onClick={() => deleteRoom()} texto="" icono={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" /></svg>} />
            </div>
        </div>
    )
}


