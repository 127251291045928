import Row from './components/Row';
import io from "socket.io-client";
import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router';
import uuid from 'react-uuid'
import sound from './multimedia/sound.mp3'
import ToggleButton from './components/ToggleButton';
import Constants from './Constants';
import Context from './Contexto';
import Spinner from './Spinner';


function RoomCommonMonitor() {

  let cont = 0;

  const context = React.useContext(Context);
  // const [qr, setQr] = useState("");
  const navigate = useNavigate();
  const [qr, setQr] = useState("");
  const [loadinQr, setLoadinQr] = useState(true);

  function changeLoading(value) {
    context.setLoading(value);
  }

  useEffect(() => {

    context.setLoading(true);
    
    if (context.userId === "") {
      navigate('/manager');
      return;
    }

    var imageQr = document.getElementById('qr-image');
    imageQr.onload = () => {
      // console.log('cargada');
      setLoadinQr(false);
    }


    // console.log("useEffect");
    const socket = io(Constants.SERVER);

    socket.on("room", data => {
      // The sound only can be played when the users had intereact with the website
      // if (document.getElementById('toggleInputButton').checked){
      //   var audio = new Audio(sound);
      //   audio.play();
      // }


      // console.log(data);
      context.setRoomSelected(data);
      if (data.qr) {
        setQr(data.qr);
      }

    });

    socket.on('connect', () => {
      console.log('Socket connected');
      socket.emit('joinTheRoomMonitor', context.roomSelected.room);
      context.setLoading(false);

    });
    socket.on('disconnect', () => {
      console.log('Socket disconnect');
      context.setLoading(true);

    });

    window.addEventListener('popstate', function (event) {
      console.log('Socket force disconnect, user click go back button');
      socket.emit('forceDisconnect');
      });




  }, []);
  if (context.roomSelected != null) {
    return (
      <div className='container-room-common-monitor'>
        <Spinner cargando={context.loading ? true : false} />
        <div className='rows-container'>
          {context.roomSelected.turns.map((item, i) => {
            if (item.active) {
              cont += 1;
              if (cont % 2 === 0) {
                return <Row key={uuid()} indexTurn={i} evenOrOdd="even" />
              }
              else {
                return <Row key={uuid()} indexTurn={i} evenOrOdd="odd" />
              }
            }
          })}
        </div>
        <div className='container-center'>
        <Spinner cargando={loadinQr ? true : false} />
          <div className='room-ids-container'>
            <div className='web'>liver.es</div>
            <div className='text-id'>Room {context.roomSelected.room}</div>
            <div className='qr-id'><img src={qr} className='room-ids-container' id='qr-image' /></div>
            
          </div>
        </div>

      </div>
    )
  }
  else {
    return (
      <div><Spinner cargando={true} /></div>
    )
  }

}

export default RoomCommonMonitor;
