import React, { useEffect } from 'react'
import { useState } from 'react';
import Constants from './Constants';
import { useNavigate } from 'react-router';
import BotonVolver from './components/BotonVolver';
import Context from './Contexto';
import Spinner from './Spinner';
import Utilities from './Utilities';

export default function NewTurn() {
    const context = React.useContext(Context);
    const [room, setRoom] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [door, setDoor] = useState("");
    const navigate = useNavigate();

    function changeStateLoading(param) {
        context.setLoading(param);
    }
    useEffect(() => {
        if (context.userId === "") {
            // console.log('dentro');
            navigate('/manager');
        }

    }, [])
    function checkDoor(event) {
        setDoor(event.target.value);
    }
    function checkName(event) {
        setName(event.target.value);
    }
    function checkPhone(event) {
        setPhone(event.target.value);
    }
    function sendForm(event) {
        try {
            event.preventDefault();
            changeStateLoading(true);

            let data = {
                userId: context.userId,
                roomId: context.roomSelected.room,
                name: name,
                door: door,
                phone: phone,
            };

            fetch(`${Constants.SERVER}/new/turn`, {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        const _room = result.all.rooms.find((item) => {
                            if (item.room === context.roomSelected.room) {
                                return item;
                            }
                        })
                        // console.log()
                        context.setRoomSelected(_room);
                        changeStateLoading(false);
                        try {
                            Utilities.printTurn(result.turnAdded.id, context.roomSelected.qr, context.roomSelected.room)

                        }
                        catch (error) {
                            alert('Para poder imprimir el ticket debe de permitir las ventanas emergentes para https://liver.es')
                        }
                        navigate('/manager/room/edit');


                        // if (result.success) {
                        //     changeStateLoading(false);
                        //     navigate(`/manager/room/edit`);
                        // }
                        // else {
                        //     changeStateLoading(false);
                        //     alert("Warning, There have been problems");
                        // }

                    },
                    (error) => {
                        changeStateLoading(false);
                        console.log(error);
                        alert('Ups, algo salió mal');
                    }
                )
        } catch (error) {
            changeStateLoading(false);
            console.log(error);
            alert('Ups, algo salió mal');
        }

        function print() {
            
        }

    }

    return (
        <div>
            <BotonVolver url='/manager/room/edit' />
            <div className="logging-manager-container">
                <Spinner cargando={context.loading ? true : false} />

                <form className="half-width">
                    <h3>Turn</h3>

                    <label htmlFor="fname">Door</label>
                    <input type="text" id="fname" name="firstname" placeholder="Door" value={door} onChange={checkDoor} />

                    <label htmlFor="fname">Name</label>
                    <input type="text" id="fname" name="firstname" placeholder="User name" value={name} onChange={checkName} />


                    <label htmlFor="fphone">Phone</label>
                    <input type="text" id="fphone" name="firstname" placeholder="User phone" value={phone} onChange={checkPhone} />

                    <input type="submit" value="Save" onClick={sendForm} />
                </form>
            </div>

        </div>

    )
}



